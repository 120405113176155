/* eslint-disable react/display-name */
import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import MaterialTable from 'material-table'
import _ from 'lodash'
import moment from 'moment'
import {
  CandidatureText,
  FavoritesTableStyled,
  Grow,
  LimitedDefaultCol,
  MTLinearPercentStyled,
  OfferStateImageStyled,
  SearchCandidatureName,
  SubtextStyled
} from './styled'
import {
  CustomAlert,
  FavouriteIcon,
  MTCircularPercent,
  MTCitationStatusTextColor,
  MTExporterButton,
  MTImage,
  MTText,
  MTToolbar,
  MTVideo
} from '../../atoms'
import Tag from '../../atoms/tag/index'
import {
  ModalAnnouncementQuery,
  ModalSuggestChangeStatusAnnouncement,
  ModalMailCustomQuery,
  ModalChangeStatus,
  ModalChangeTagsQuery,
  ModalRequireDocQuery,
  OfferStateImage,
  TableFilters
} from '../../molecules'
import {getCurrentSectionKey, INTEGRATIONS, MODULES, QUERY_KEYS, ROLES, SECTION_KEYS} from '../../../config/constants'
import StatusGroupType from '../../../models/status-group-type'
import {Grid} from '@material-ui/core'
import {AuthUtils} from "../../../redux/auth";
import routes from "../../../config/routes";
import offerCandidatureStatusType from "../../../models/offer-candidature-status-type";
import autoStatusDomains from "../../../utils/auto-status-domains";
import {searchCurrentOfferHasCandidature} from "../../../utils/offer";
import {matchPath, useParams} from "react-router";
import {StatusType} from "../../../models";
import {useAppContext} from "../../../context/appContext";
import {useMutation} from "@tanstack/react-query";
import useInbox from "../../../api/queries/useInbox";
import useInboxFields from "../../../api/queries/useInboxFields";
import useTotalCount from "../../../api/queries/useTotalCount";
import useStatusTypes from "../../../api/queries/useStatusTypes";
import useTagsTypes from "../../../api/queries/useTagsTypes";
import * as api from "../../../api/index";
import { useTheme } from '@material-ui/styles'
import {
  invalidateQueriesByKeys,
  mutationChangeStatusCallback,
  mutationChangeTagsCallback,
  mutationFavoritesCallback,
  mutationMarkAsReadCallback,
  mutationMarkAsUnreadCallback,
  mutationPostCitationCallback,
  refetchQueriesByKeys,
  removeQueriesByKeys,
} from "../../../api/mutations/mutationCallbacks";
import {toast} from "react-toastify";
import useFavorites from "../../../api/queries/useFavorites";
import useSavedSearchesItem from "../../../api/queries/useSavedSearchesItem";
import {useFunnelStatusTypes, useGetPhasesOfStatusTypesQuery} from "../../../api/queries/useFunnelStatusTypes";
import { iso8601Format } from '../../../utils/date'
import { Chip } from '@material-ui/core'
import { FeatureGuard } from '../../../utils/guard'
import { isJSON } from "../../../utils/validation";
import { jsonToShow } from "../../../utils/formatting";
import Snackbar from '@material-ui/core/Snackbar'
import {refetchQueryOnCacheDataLost} from "../../../api/utils";

const getReadClass = rowData => (_.get(rowData, 'was_read', false) ? 'was_read' : 'not_read')

const UseInboxQuery = ({ module, filters, range, sort, updateQueryData }) => {
  const { data, isFetched, dataUpdatedAt, refetch } = useInbox(module, filters, range, sort);

  refetchQueryOnCacheDataLost(data, dataUpdatedAt, refetch);
  updateQueryData(data, isFetched);

  return null;
}
const UseFavoritesQuery = ({module, filters, range, sort, updateQueryData}) => {
  const {data, isFetched, dataUpdatedAt, refetch} = useFavorites(module, filters, range, sort);

  refetchQueryOnCacheDataLost(data, dataUpdatedAt, refetch);
  updateQueryData(data, isFetched);

  return null;
}
const UseSavedSearchesItem = ({module, savedSearchItemId, filters, filterGroup, range, sort, updateQueryData}) => {
  const {data, isFetched, dataUpdatedAt, refetch} = useSavedSearchesItem(module, savedSearchItemId, filters, filterGroup, range, sort);

  refetchQueryOnCacheDataLost(data, dataUpdatedAt, refetch);
  updateQueryData(data, isFetched);

  return null;
}

const CustomTable = ({
                       i18n,
                       history,
                       client,
                       auth,
                       actions,
                       match,
                       location,
                       showChangeStatus,
                       notificationMultipleEnabled,
                       showFilterGroup,
                       offer,
                       updateParentRowsSelected,
                       updateParentTotal,
                       user_email,
                       suggestChangeStatusModal,
                       appBarForceTotal,
                       setTableRef = null,
                       ...props
                     }) => {
  const {
    filters,
    sort, setSort,
    range, setRange,
    page, setPage,
    perPage, setPerPage,
    module,
    filterGroup,
    savedSearchItems,
    setFilterGroupSelected,
    clearContextIfNeeded,
    setGoBackLocation
  } = useAppContext();

  useEffect(() => {
    clearContextIfNeeded(MODULES.currentModule());
  }, [module, location.pathname]);

  let { savedSearchItemId } = useParams();
  if (offer) {
    savedSearchItemId = offer.id;
  }
  const {data: total, isFetched: isFetchedTotal} = useTotalCount(
    module,
    getCurrentSectionKey(),
    ![SECTION_KEYS.SAVED_SEARCHES, SECTION_KEYS.SAVED_SEARCHES_ITEM].includes(getCurrentSectionKey()) ? filters[getCurrentSectionKey()] : filters[getCurrentSectionKey()][savedSearchItemId],
    getCurrentSectionKey() === SECTION_KEYS.SAVED_SEARCHES_ITEM ? savedSearchItemId : null
  );
  const {data: inboxFields, isFetched: isFetchedInboxFields} = useInboxFields(module);
  const {data: statusTypes, isFetched: isFetchedStatusTypes} = useStatusTypes(module, user_email);
  const {data: statusFunnelTypes, isFetched: isFetchedStatusFunnelTypes} = useFunnelStatusTypes(module);
  const phasesFunnelTypes = useGetPhasesOfStatusTypesQuery(module);
  const {data: tagsTypes, isFetched: isFetchedTagsTypes} = useTagsTypes(module);
  const mutationPostFavorites = useMutation(api.postFavorites_query);
  const mutationDeleteFavorites = useMutation(api.deleteFavorites_query);
  const mutationPostReads = useMutation(api.postReads_query);
  const mutationDeleteReads = useMutation(api.deleteReads_query);
  const mutationChangeStatus = useMutation(api.changeStatus_query);
  const mutationUpdateStatesSelectionFunnel = useMutation(api.updateNewStatesSelectionFunnel);
  const mutationChangeTags = useMutation(api.changeTags_query);
  const mutationPostCitation = useMutation(api.postOrPatchCitations);
  const mutationDocRequired = useMutation(api.requireDocQuery);

  const [isFetching, setIsFetching] = useState(true);
  const [isFetchedRenderData, setIsFetchedRenderData] = useState(true);
  const [renderData, setRenderData] = useState([]);
  const [statusTypesByUser, setStatusTypesByUser] = useState([])
  const [currentTags, setCurrentTags] = useState([])

  useEffect(() => {
    if (statusTypes) {
      let showOnlyManualStatus = true;
      if (user_email) {
        autoStatusDomains().map(item => {
          if (user_email.includes(item)) {
            showOnlyManualStatus = false;
          }
        });
      }
      if (showOnlyManualStatus) {
        setStatusTypesByUser(statusTypes.filter(item => item.automatic === false));
      } else {
        setStatusTypesByUser(statusTypes);
      }
    }
  }, [statusTypes]);

  useEffect(() => {
    setIsFetching(!isFetchedRenderData || !isFetchedInboxFields || !isFetchedStatusTypes || !isFetchedTagsTypes || !isFetchedStatusFunnelTypes);
    // eslint-disable-next-line
  }, [isFetchedRenderData, isFetchedInboxFields, isFetchedStatusTypes, isFetchedTagsTypes, isFetchedStatusFunnelTypes]);

  const inboxFieldsClone = _.cloneDeep(inboxFields);

  const [isOpenMailCustomModal, setOpenMailCustomModal] = useState(false);
  const [totalRowsSelected, setTotalRowsSelected] = useState(0);
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
  const [openChangeTagsModal, setOpenChangeTagsModal] = useState(false);
  const [announcement, setAnnouncement] = useState(null);
  const [announcementModal, setAnnouncementModal] = useState(false);
  const [requireDoc, setRequireDoc] = useState(null);
  const [requireDocModal, setRequireDocModal] = useState(false);
  const [totalByStatusGroup, setTotalByStatusGroup] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({ message: '', severity: '', variant: ''});

  const [rowsSelected, setRowsSelected] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    if (total && getCurrentSectionKey() === SECTION_KEYS.SAVED_SEARCHES_ITEM) {
      let counter = 0;

      let currentFilterGroup = filterGroup[getCurrentSectionKey()]
      && filterGroup[getCurrentSectionKey()][savedSearchItemId]
        ? filterGroup[getCurrentSectionKey()][savedSearchItemId]
        : null;
      switch (module) {
        case MODULES.ID_TALENT_ACQUISITION: {
          let currentGroupStatus = _.find(StatusGroupType, {id: currentFilterGroup ? currentFilterGroup : 1});
          currentGroupStatus = currentGroupStatus ? currentGroupStatus.status : null

          if (currentGroupStatus) {
            let keys = Object.keys(total);
            let statusInRows = [];
            keys.map(key => {
              let status = key.split('_').pop();
              status = status ? +status : null;
              if (status) statusInRows.push(status);
              if (currentGroupStatus.includes(status)) {
                counter += total[key];
              }
            });
            if (!currentFilterGroup) {
              let statusGroupKeys = Object.keys(StatusGroupType);
              let terminate = false;

              statusGroupKeys.map(key => {
                if (!terminate) {
                  const filteredArray = statusInRows.filter(value => StatusGroupType[key].status.includes(value));
                  if (filteredArray.length > 0) {
                    setFilterGroupSelected(getCurrentSectionKey(), savedSearchItemId, StatusGroupType[key].id);
                    localStorage.setItem('currentFilterGroupInState', StatusGroupType[key].id)
                    terminate = true;
                  }
                }
              });
            }
          }
          break;
        }
        case MODULES.ID_EMPLOYEE_LINE:
          if (!currentFilterGroup) {
            const firstStatusValue = () => {
              let totalKeys = Object.keys(total);
              let resultKey = '';
              totalKeys.map(key => {
                if (resultKey) return;
                let splitKey = key.split('total_status_');
                if (splitKey.length > 1) resultKey = key;
              })
              setFilterGroupSelected(getCurrentSectionKey(), savedSearchItemId, resultKey.replace('total_status_', ''));
              localStorage.setItem('currentFilterGroupInState', resultKey.replace('total_status_', ''))
              return total[resultKey];
            }
            counter = firstStatusValue();
          }
          break;
        default:
          break;
      }
      setTotalByStatusGroup(counter);
      updateParentTotal(counter);
      if (!currentFilterGroup || localStorage.getItem('currentFilterGroupInState') != currentFilterGroup) {
        localStorage.setItem('currentFilterGroupInState', currentFilterGroup)
        setPage({...page, [getCurrentSectionKey()]: 0});
      }

    } else if (total && getCurrentSectionKey() === SECTION_KEYS.PUSH_OFFER_CANDIDATES) {
      appBarForceTotal.setForceTotalOffers(getParentTotal());
    }
  }, [total, filterGroup]);

  const displayAlert = (data, callback = null) => {
    setAlert(data);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false)
      callback && callback();
    }, 3500);
  }

  const getParentTotal = () => {
    return isFetchedTotal && total && !offer
      ? total.total
      : (offer ? offer.interested + offer.not_interested + offer.unanswered : 0)
  }

  const updateQueryData = (data, isFetched) => {
    setRenderData(data);
    setIsFetchedRenderData(isFetched);
  }

  const getInboxDataBySection = () => {
    let currentFilters = ![SECTION_KEYS.SAVED_SEARCHES_ITEM].includes(getCurrentSectionKey()) ? filters[getCurrentSectionKey()] : filters[getCurrentSectionKey()][savedSearchItemId];
    let props = {
      module: module,
      sectionKey: getCurrentSectionKey(),
      filters: currentFilters,
      range: range[getCurrentSectionKey()],
      sort: sort[getCurrentSectionKey()],
      updateQueryData
    };

    switch (getCurrentSectionKey()) {
      case SECTION_KEYS.INBOX:
        return <UseInboxQuery {...props} />
      case SECTION_KEYS.PUSH_OFFER_CANDIDATES:
        return <UseInboxQuery {...props}/>
      case SECTION_KEYS.FAVORITES:
        return <UseFavoritesQuery {...props}/>
      case SECTION_KEYS.SAVED_SEARCHES_ITEM:
        // eslint-disable-next-line
        let strFilters = savedSearchItems && savedSearchItems[savedSearchItemId] ? savedSearchItems[savedSearchItemId].fields : null;
        props.filters = strFilters ? JSON.parse(strFilters) : {};
        props.filterGroup = filterGroup[getCurrentSectionKey()] && filterGroup[getCurrentSectionKey()][savedSearchItemId] ? filterGroup[getCurrentSectionKey()][savedSearchItemId] : null;
        props.savedSearchItemId = savedSearchItemId;
        return <UseSavedSearchesItem {...props}/>
    }
  }

  const InboxBySectionComponent = () => {
    return getInboxDataBySection();
  }

  let isOnlyReader;
  if (module === MODULES.ID_FEEDBACK) {
    isOnlyReader = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_feedback)
  } else if (module === MODULES.ID_EMPLOYEE_LINE) {
    isOnlyReader = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_employee_line)
  } else if (module === MODULES.ID_ON_BOARDING) {
    isOnlyReader = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_on_board)
  } else if (module === MODULES.ID_EXIT_INTERVIEW) {
    isOnlyReader = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_exit_interview)
  } else {
    isOnlyReader = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_talent_acquisition)
  }

  const hasPullFeature = module === MODULES.ID_TALENT_ACQUISITION && FeatureGuard.canAccessToPullOffers();

  if (inboxFieldsClone && module === MODULES.ID_TALENT_ACQUISITION && match.path === routes.offerCandidatures) {
    inboxFieldsClone.splice(1, 0, {
      id: -20,
      remote_name: 'offer_has_candidatures.offer.state',
      name: i18n.processesTable.offerCandidatureState
    })
  }

  const tableRef = useRef(null)

  const updateTableData = () => tableRef && tableRef.current && tableRef.current.onQueryChange();

  useEffect(() => {
    updateTableData();
    // eslint-disable-next-line
  }, [renderData, inboxFields, statusTypes])

  useEffect(() => {
    setRange({
      ...range,
      [getCurrentSectionKey()]: api.getRange(page[getCurrentSectionKey()], perPage[getCurrentSectionKey()])
    });
    // eslint-disable-next-line
  }, [page, perPage])

  useEffect(() => {
    setTotalRowsSelected(getTotalRowsSelected);
    if (updateParentRowsSelected) updateParentRowsSelected(rowsSelected);
  }, [rowsSelected])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (setTableRef !== null) {
      setTableRef(tableRef)
    }
  }, []);

  const getTotalRowsSelected = () => {
    return rowsSelected ? _.map(rowsSelected, (item) => item.id).length : 0;
  }

  const checkRow = event => {
    if (event.length === 1) {
      let row = event[0];
      let tags = row.hasOwnProperty('tags') ? row.tags : [];
      setCurrentTags(tags);
    } else {
      setCurrentTags([]);
    }
    setRowsSelected(event)
  }

  const handleCloseChangeStatusModal = () => setOpenChangeStatusModal(false)
  const handleOpenChangeStatusModal = () => setOpenChangeStatusModal(true)

  const removeInboxesQueriesBySection = (extraQueries = []) => {
    let queriesToRemove = [`${module}:${QUERY_KEYS.FAVORITES}`, `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}`];
    queriesToRemove = queriesToRemove.concat(extraQueries);
    if (getCurrentSectionKey() !== SECTION_KEYS.INBOX) {
      queriesToRemove.push(`${module}:${QUERY_KEYS.INBOX}`);
    }
    removeQueriesByKeys(queriesToRemove);
  }

  const refetchInboxesQueriesBySection = (extraQueries = []) => {
    let queriesToRefetch = [`${module}:${QUERY_KEYS.INBOX}`,`${module}:${QUERY_KEYS.FAVORITES}`, `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}`];
    queriesToRefetch = queriesToRefetch.concat(extraQueries);
    refetchQueriesByKeys(queriesToRefetch);
  }

  const handlePostFavorite = async (isFavorite, ids) => {
    const rows = ids || rowsSelected;
    if (_.size(rows) > 0) {
      const ids = _.map(rows, 'id');
      if (isFavorite) {
        await mutationPostFavorites.mutateAsync({
          ids,
          module
        }, mutationFavoritesCallback(ids, module, getCurrentSectionKey(), isFavorite, renderData))
          .then(() => {
            refetchInboxesQueriesBySection();
          });
      } else {
        await mutationDeleteFavorites.mutateAsync({
          ids,
          module
        }, mutationFavoritesCallback(ids, module, getCurrentSectionKey(), isFavorite, renderData))
          .then(() => {
            refetchInboxesQueriesBySection();
          });
      }
      let queriesToRefetch = [];
      queriesToRefetch.push(`${module}:${QUERY_KEYS.FAVORITES}:${QUERY_KEYS.TOTAL_COUNT}`);
      ids.map(id => queriesToRefetch.push(`${module}:${QUERY_KEYS.PROFILE}:${id}`));
      if (queriesToRefetch) refetchQueriesByKeys(queriesToRefetch);
    }
  }

  const handleMarkAsRead = () => {
    if (_.size(rowsSelected) > 0) {
      const ids = _.map(rowsSelected, 'id');
      mutationPostReads.mutateAsync({ids, module},
        mutationMarkAsReadCallback(ids, module, renderData))
        .then(() => {
          refetchInboxesQueriesBySection();
        });
    }
  }

  const handleUnmarkAsRead = () => {
    if (_.size(rowsSelected) > 0) {
      const ids = _.map(rowsSelected, 'id');
      mutationDeleteReads.mutateAsync({ids, module},
        mutationMarkAsUnreadCallback(ids, module, renderData))
        .then(() => {
          refetchInboxesQueriesBySection();
        });
    }
  }

  const clearRowsSelected = () => {
    setRowsSelected([]);
    tableRef.current && tableRef.current.onAllSelected(false);
  }

  const onSubmitChangeStatus = (status, date) => {
    setOpenChangeStatusModal(false);
    if (_.size(rowsSelected) > 0) {
      const ids = _.map(rowsSelected, 'id');
      let data = [];
      let queriesToRefetch = [];
      ids.map(id => {
        data.push({id: id, status_id: status, date: date});
        queriesToRefetch.push(`${module}:${QUERY_KEYS.PROFILE}:${id}`)
      });
      mutationChangeStatus.mutateAsync({data, module},
        mutationChangeStatusCallback(ids, module, status, renderData))
        .then(() => {
          if ([SECTION_KEYS.SAVED_SEARCHES_ITEM].includes(getCurrentSectionKey())) {
            removeInboxesQueriesBySection([
              `${module}:${getCurrentSectionKey()}:${QUERY_KEYS.TOTAL_COUNT}:${savedSearchItemId}`,
              `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:1`,
              `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:2`,
              `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:3`,
            ]);
          }
          refetchInboxesQueriesBySection(queriesToRefetch);
        });
      clearRowsSelected();
    }
  }

  const onSubmitChangeFunnelStatus = (status) => {
    if (_.size(rowsSelected) > 0) {
      const ids = _.map(rowsSelected, 'id');
      let data = [];
      let queriesToRefetch = [];
      let queriesToInvalidate = [];
      ids.map(id => {
        data.push({id: id, status_id: status.id, date: iso8601Format()});
        queriesToRefetch.push(`${module}:${QUERY_KEYS.PROFILE}:${id}`)
        queriesToInvalidate.push(`${QUERY_KEYS.OBSERVATIONS}:${id}`)
      });

      mutationUpdateStatesSelectionFunnel.mutateAsync(data, {
        onSuccess: () => {
          refetchInboxesQueriesBySection(queriesToRefetch);
          invalidateQueriesByKeys(module, queriesToInvalidate);
        },
      });
      displayAlert({ message: i18n.processesTable.statusChanged, severity: 'success', variant: 'filled' });
      clearRowsSelected();
      
    }
  }

  const onSubmitChangeTags = (tags) => {
    setOpenChangeTagsModal(false)
    if (_.size(rowsSelected) > 0) {
      const ids = _.map(rowsSelected, 'id');
      mutationChangeTags.mutateAsync({ids, tags, module},
        mutationChangeTagsCallback(ids, module, tags, tagsTypes, renderData))
        .then(() => {
          let profileQueriesToRefetch = [];
          ids.map(id => profileQueriesToRefetch.push(`${module}:${QUERY_KEYS.PROFILE}:${id}`));
          refetchInboxesQueriesBySection(profileQueriesToRefetch);
        });
      clearRowsSelected();
    }
  }

  const handleRowClick = (e, row) => {
    if (getCurrentSectionKey() === SECTION_KEYS.SAVED_SEARCHES_ITEM) {
      history.push(`/${module}/${SECTION_KEYS.SAVED_SEARCHES}/${savedSearchItemId}/profile/${row.id}`);
    } else if (getCurrentSectionKey() === SECTION_KEYS.PUSH_OFFER_CANDIDATES) {
      setGoBackLocation(`${location.pathname}?keepFilters=1`);
      history.push(`/${module}/${SECTION_KEYS.INBOX}/profile/${row.id}`);
    } else {
      history.push(`/${module}/${getCurrentSectionKey()}/profile/${row.id}`);
    }
  }

  const handleChangePage = newPage => {
    setPage({...page, [getCurrentSectionKey()]: newPage});
  }

  const handleChangeRowsPerPage = newPerPage => {
    setPage({...page, [getCurrentSectionKey()]: 0})
    setPerPage({...perPage, [getCurrentSectionKey()]: newPerPage});
  }

  const handleOrderChange = (column, orderDirection) => {
    setPage({...page, [getCurrentSectionKey()]: 0})
    setSort({...sort, [getCurrentSectionKey()]: orderDirection ? [column.field, orderDirection] : ["", ""]});
  }

  const handleFilterGroupSelected = selected => {
    setFilterGroupSelected(getCurrentSectionKey(), savedSearchItemId, selected);
    refetchInboxesQueriesBySection();
  }

  const renderState = (field, rowData, statusTypes, styles) => {
    const fieldSelected = _.get(rowData, field)
    if (!fieldSelected) return null
    const status = _.find(statusTypes, (item) => item.id.toString() === fieldSelected.toString())
    return <MTText style={{minWidth: '80px', ...styles}} value={status ? i18n.status[status.id] : ''}
                   color={status ? status.color : null}/>
  }

  const renderCitationState = (field, rowData) => {
    const fieldSelected = _.get(rowData, field)
    if (!fieldSelected) return null
    return <MTCitationStatusTextColor value={fieldSelected} />
  }

  const renderEvaluativeConfigState = (field, rowData) => {
    const fieldSelected = _.get(rowData, field)
    if (!fieldSelected) return null

    const value = i18n.evaluativeConfig[fieldSelected] || ''
    return <MTText style={{minWidth: '80px'}} value={value} />
  }

  const renderSelectionStatus = (field, rowData, statusTypes, styles) => {
    const fieldSelected = _.get(rowData, field);
    if (!fieldSelected) return null;
    
    const status = _.find(statusTypes, (item) => item.id.toString() === fieldSelected.toString());
    const color = status ? status.color : '#d3d3d3';
    
    return (
        <Chip
            label={status ? status.name : fieldSelected}
            style={{ backgroundColor: color, minWidth: '80px', height: '25px', fontSize: '13px', ...styles }}
        />
    );
};

  const renderSelectionPhases = (field, rowData, statusTypes, styles) => {
    const phaseValue = _.get(rowData, field)
    if (!phaseValue) return null;
    const phaseName = _.find(statusTypes, (item) => item.id.toString() === phaseValue.toString())

    //const color = phaseName ? phaseName.color : '#d3d3d3';
    return (
        <Chip 
            label={phaseName ? phaseName.name : phaseValue}
            style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText, height: '25px', fontSize: '13px', ...styles }}
        />
     );
  }

  const renderRecovered = (isPullAccepted) => {
    let pullIcon;

    switch (isPullAccepted) {
      case 1:
        pullIcon = <i className="fa fa-arrow-circle-up isPullAcceptedIcon" aria-hidden="true" style={{color: 'green'}}></i>;
        break;
      case 0:
        pullIcon = <i className="fa fa-clock-o isPullAcceptedIcon" aria-hidden="true" style={{color: 'grey'}}></i>;
        break;
      case -1:
        pullIcon = <i className="fa fa-2x fa-times-circle isPullAcceptedIcon" aria-hidden="true" style={{color: 'red'}}></i>;
        break;
      default:
        break;
    }

    return pullIcon;
  }

  const renderTags = (field, rowData, shortVersion= true) => {
    return _.map(rowData.tags, tag => {
      return (
        <Grid item>
          <Tag tag={tag} tagTypesReactQuery={tagsTypes} shortVersion={shortVersion}/>
        </Grid>
      )
    })
  }

  const getCurrentIntegration = () => {
    if (auth && INTEGRATIONS) {
      let currentIntegration = _.find(INTEGRATIONS, {event_type: auth.event_type})
      return currentIntegration ? currentIntegration : null;
    }
    return null
  }

  const onSubmitAnnouncement = (data) => {
    if (announcement && _.size(announcement.rowsSelected) > 0) {
      let filteredAnnouncement = announcement.rowsSelected
        .filter(item => ![StatusType.HIRED, StatusType.DISCARDED, StatusType.DISCARDED_PHASE_2].includes(item.estado));
      const ids = _.map(filteredAnnouncement, 'id');
      data.create = true;
      mutationPostCitation.mutateAsync({data, ids}, mutationPostCitationCallback(ids, module)).then(() => {
        toast.success(i18n.citations.citationCreated);

        let queriesToRefetch = [];
        let data = [];
        // TODO Fix until we merge to new phase and state system
        let status_id = getCurrentIntegration() ? StatusType.CITED : StatusType.TO_BE_CONFIRMED;
        ids.map(id => {
          data.push({id: id, status_id: status_id, date: new Date()});
          queriesToRefetch.push(`${module}:${QUERY_KEYS.PROFILE}:${id}`)
        });
          if (!FeatureGuard.canAccessToFunnelStatus()) {
            mutationChangeStatus.mutateAsync({data, module},
              mutationChangeStatusCallback(ids, module, status_id, renderData))
              .then(() => {
                if ([SECTION_KEYS.SAVED_SEARCHES_ITEM].includes(getCurrentSectionKey())) {
                  removeInboxesQueriesBySection([
                    `${module}:${getCurrentSectionKey()}:${QUERY_KEYS.TOTAL_COUNT}:${savedSearchItemId}`,
                    `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:1`,
                    `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:2`,
                    `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:3`,
                  ]);
                }
                refetchInboxesQueriesBySection(queriesToRefetch);          
              }); 
          }   
      }).catch(() => {
        toast.error(i18n.citations.citationError);
      });
      announcement.clearRowsSelected()
    }
  }

  const onSubmitRequireDoc = (data) => {
    if (requireDoc && _.size(requireDoc.rowsSelected) > 0) {
      const ids = _.map(requireDoc.rowsSelected, 'id')

      mutationDocRequired.mutateAsync({
        data,
        ids
      }, mutationChangeStatusCallback(ids, module, StatusType.DOC_REQUIRED, renderData))
        .then(() => {
          if ([SECTION_KEYS.SAVED_SEARCHES_ITEM].includes(getCurrentSectionKey())) {
            removeInboxesQueriesBySection([
              `${module}:${getCurrentSectionKey()}:${QUERY_KEYS.TOTAL_COUNT}:${savedSearchItemId}`,
              `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:1`,
              `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:2`,
              `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:3`,
            ]);
          }
          toast.success(i18n.requireDoc.docRequired)
        }).catch(() => toast.error(i18n.requireDoc.errorDocRequire));

      requireDoc.clearRowsSelected();
    }
  }

  const handleSubmitMailCustom = () => {
    if (_.size(rowsSelected) > 0) {
      // const ids = _.map(rowsSelected, 'id')
      // sendEmailCustom(ids, mailCustom.subject, mailCustom.message, module).then(() => clearRowsSelected())
      clearRowsSelected() // TODO temporal hasta tener mutation sendEmailCustom !Es línea de empleado
    }
  }

  const getInitials = fullName => {

    function getInitial(name) {
      return name && name.length ? name[0].toUpperCase() : '';
    }

    const fullNameSplit = fullName ? fullName.split(' ') : false;
    if (fullNameSplit && fullNameSplit.length > 1) {
      return getInitial(fullNameSplit[0]) + getInitial(fullNameSplit[1]);
    }
    return '';
  }

  const renderField = (field, rowData, statusTypes) => {
    const fieldData = _.get(rowData, field);

    switch (field) {
      case 'tags':
        return (
          renderTags('tags', rowData, false)
        )
      case 'is_favorite':
        return (
          <Grid justifyContent="start" container alignItems='flexStart'>
            <Grid item lg={4}>
              <FavouriteIcon
                // style={{ textAlign: 'center' }}
                favorite={_.get(rowData, 'is_favorite')}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (!isFetching) handlePostFavorite(!_.get(rowData, 'is_favorite'), [rowData])
                }}
              />
            </Grid>
            {rowData && rowData.tags && rowData.tags.length > 0 &&
               <Grid className="tagsColumn" container alignItems='center' justifyContent='start' item lg={8}>
               {renderTags('tags', rowData)}
             </Grid> 
            }
          </Grid>)
      case 'search_candidature_name':
        return (
          <Grid container justifyContent='start' className={["wrapImageName", getReadClass(rowData)]}>
            <Grid item>
              <MTImage foto={_.get(rowData, 'foto')} inicials={getInitials(_.get(rowData, field))}
                       pullIcon={hasPullFeature && rowData.hasOwnProperty('is_pull_accepted')
                         ? renderRecovered(rowData.is_pull_accepted) : null}/>
            </Grid>
            <Grid item>
              <SearchCandidatureName value={_.get(rowData, field)}/>
              {_.get(rowData, 'candidatura') ? <CandidatureText value={_.get(rowData, 'candidatura')}/> : null}
            </Grid>
          </Grid>
        )
      case 'estado':
      case 'state.id':
        return renderState(field, rowData, statusTypes, getReadClass(rowData) === 'was_read' ? { fontWeight: 400 } : {})
      case 'evaluative_config_candidatures.0.status':
        return renderEvaluativeConfigState(field, rowData, statusTypes)
      case 'selection_status':
        return renderSelectionStatus(field, rowData, statusFunnelTypes?.data ?? [], getReadClass(rowData) === 'was_read' ? {fontWeight: 500} : {})
      case 'selection_phase':
        return renderSelectionPhases(field, rowData, phasesFunnelTypes ?? [], getReadClass(rowData) === 'was_read' ? {fontWeight: 500} : {})
      case 'last_citation.status':
        return renderCitationState(field, rowData)
      case 'employeeHasProcess.employee.phone':
        return <SubtextStyled value={_.get(rowData, field)} className={getReadClass(rowData)}/>
      case 'disponibilidad':
      case 'update_at':
      case 'create_at':
      case 'fecha_nacimiento':
      case 'inscripcion':
        return <MTText value={moment.utc(_.get(rowData, field)).format('DD/MM/YYYY')} className={getReadClass(rowData)}/>
      case 'createdAt':
        return (
            <MTText value={moment.utc(_.get(rowData, field)).format('DD/MM/YYYY')} className={getReadClass(rowData)}/>
        )
      case 'profile':
      case 'skills':
        return <MTLinearPercentStyled value={Math.round(_.get(rowData, field))} className={getReadClass(rowData)}/>
      case 'cv':
        return <MTExporterButton cv={_.get(rowData, field)}/>
      case 'foto':
        return null
      case 'matching':
        return <MTCircularPercent fontSize="15px" width="54px" height="54px" thickness={4.5} left={true}
                                  valid={_.get(rowData, 'apto', undefined)}
                                  value={Math.round(_.get(rowData, field))} className={getReadClass(rowData)}/>
      case 'video':
        return <MTVideo videoUrl={_.get(rowData, field)}/>
      case 'centro_trabajo':
        return (
          <LimitedDefaultCol title={_.get(rowData, field)} value={Array.isArray(_.get(rowData, field)) ? _.get(rowData, field).join(", ") :_.get(rowData, field)}
                             className={getReadClass(rowData)}/>
        )
      case 'offer_has_candidatures.offer.state':
        return <OfferStateImageStyled><OfferStateImage
          state={_.get(searchCurrentOfferHasCandidature(rowData, _.get(offer, 'id')), 'state')}/></OfferStateImageStyled>
      case 'offer_title':
        return <MTText value={_.get(rowData, field)} style={{minWidth: '250px'}}/>
      default:
        if(Array.isArray(fieldData)){
          return fieldData.join(", ");
        }
        if (typeof fieldData === 'object') {
          return JSON.stringify(fieldData);
        }
        if(typeof(fieldData) === 'string' && isJSON(fieldData)) {
          return jsonToShow(fieldData);
        }
        if (typeof(fieldData) === 'string'
            && fieldData.match(/\d{2}\/\d{2}\/\d{4} \d\d:\d\d/)){
          return <MTText value={fieldData.split(' ')[0]} className={getReadClass(rowData)}/>
        }else if (typeof(fieldData) === 'string'
            && fieldData.match(/\d{4}-\d{2}-\d{2}T\d\d:\d\d/)) {
          return <MTText value={moment.utc(fieldData).format('DD/MM/YYYY')} className={getReadClass(rowData)}/>
        }else{
          return <LimitedDefaultCol value={fieldData} className={getReadClass(rowData)}/>
        }
    }
  }

  const putFavoriteColumnFirst = objectWithColumnsMapped => {
    const first = "is_favorite";
    return objectWithColumnsMapped.sort(function (x, y) {
      return x.field === first ? -1 : y.field === first ? 1 : 0;
    });
  }
  
  const getColumns = (columns, statusTypes) => {
     if (columns && columns.length > 0) {
      let objectWithColumnsMapped = _.map(columns, item => {          
        /* Remove column foto from table */
        if (item.remote_name !== 'foto') {
          switch (item.remote_name) {
            case 'search_candidature_name':
              return ({
                title: i18n.inboxColumns[item.name] || item.name,
                field: item.remote_name,
                headerStyle: {
                  width: '280px',
                },
                render: rowData => renderField(item.remote_name, rowData, statusTypes)
              })
            case 'foto':
              return null
            case 'direccion':
              return ({
                title: item.name,
                field: item.remote_name,
                headerStyle: {
                  width: '250px',
                },
                render: rowData => renderField(item.remote_name, rowData, statusTypes)
              })
            default:
              return ({
                title: i18n.inboxColumns[item.name] || item.name,
                field: item.remote_name,
                render: rowData => renderField(item.remote_name, rowData, statusTypes)
              })
          }
        }
        /* Remove undefined generate by foto */
      }).filter(item => item !== undefined)

      return (
        putFavoriteColumnFirst(objectWithColumnsMapped)
      );
    }
    return []
  }

  let toolbarOptions = []
  if (!isOnlyReader) {
    toolbarOptions = [
      {
        id: 'markAsRead',
        label: i18n.processesTable.markAsRead,
        onClick: () => {
          handleMarkAsRead()
          clearRowsSelected()
        }
      },
      {
        id: 'unmarkAsRead',
        label: i18n.processesTable.unmarkAsRead,
        onClick: () => {
          handleUnmarkAsRead()
          clearRowsSelected()
        }
      },
      {
        id: 'markFavorite',
        label: i18n.processesTable.markFavorite,
        onClick: () => {
          handlePostFavorite(true)
          clearRowsSelected()
        }
      },
      {
        id: 'removeFavorites',
        label: i18n.processesTable.removeFavorites,
        onClick: () => {
          handlePostFavorite(false)
          clearRowsSelected()
        }
      },
      module !== MODULES.ID_EXIT_INTERVIEW && showChangeStatus ? {
          id: 'changeStatus', label: i18n.processesTable.changeStatus,
          onClick: () => {
            handleOpenChangeStatusModal()
          }
      } : null,
      module === MODULES.ID_TALENT_ACQUISITION ? {
        id: 'announcement',
        label: i18n.peopleTable.quoteCall,
        onClick: ({...props}) => {
          setAnnouncementModal(true)
          setAnnouncement(props)
        }
      } : null,
      module === MODULES.ID_TALENT_ACQUISITION ? {
        id: 'requireDoc',
        label: i18n.peopleTable.requireDoc,
        onClick: ({...props}) => {
          setRequireDocModal(true)
          setRequireDoc(props)
        }
      } : null,
      module !== MODULES.ID_EXIT_INTERVIEW && tagsTypes && tagsTypes.length > 0 ? {
        id: 'changeTags',
        label: i18n.processesTable.changeTags,
        onClick: () => {
          setOpenChangeTagsModal(true)
        }
      } : null,
      module !== MODULES.ID_EXIT_INTERVIEW && notificationMultipleEnabled && {
        id: 'sendNotification',
        label: i18n.modalMailCustom.quoteCall,
        onClick: () => {
          setOpenMailCustomModal(true)
        }
      },
      ...actions
    ];
  }

  const tableOptions = {
    paginationType: 'normal',
    emptyRowsWhenPaging: false,
    loadingType: 'linear',
    selection: true,
    pageSizeOptions: [20, 50, 100, 200],
    pageSize: perPage[getCurrentSectionKey()],
  }

  let mainStatusTypes;

  if (match.path === routes.offerCandidatures) {
    mainStatusTypes = _.map(offerCandidatureStatusType, status => {
      return {id: status, name: i18n.offerStates[status], total: offer ? offer[status] : 0}
    });
  } else if (getCurrentSectionKey() === SECTION_KEYS.SAVED_SEARCHES_ITEM) {
    mainStatusTypes = _.map(_.filter(statusTypes, {is_main_type: true}), status => {
      return total ? {id: status.id, name: status.name, total: total[`total_status_${status.id}`]} : {};
    });
  }

  const exportFilter = {...filters};
  if (rowsSelected && rowsSelected.length > 0) exportFilter.id = _.map(rowsSelected, item => item.id);

  const onItemClick = (id) => {
    _.find(toolbarOptions, {id}).onClick({
      rowsSelected,
      // updateQuery: updateData, // TODO: actualizar
      clearRowsSelected
    });
  }

  const tableComponents = {
    Container: props => <div {...props} />,
    Toolbar: () => (
    <>         
      <Snackbar
        open={showAlert}
        key="custom-alert"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={6000}
      >
        <CustomAlert
        severity={alert.severity}
        message={alert.message}
        variant={alert.variant}
        sx={{ width: '100%' }}
        />
      </Snackbar>
   
      <MTToolbar
        module={module}
        onSubmitChangeStatus={onSubmitChangeStatus} statusTypes={statusTypesByUser}
        onSubmitChangeFunnelStatus={onSubmitChangeFunnelStatus} funnelStatusTypes={statusFunnelTypes?.data ?? []}
        totalRowsSelected={totalRowsSelected}
        totalRows={totalByStatusGroup ? totalByStatusGroup : (total ? total.total : 0)}
        onItemClick={onItemClick}
        variant={showFilterGroup ? 'filterGroup' : null}
        options={toolbarOptions}
        disabled={_.isEmpty(rowsSelected)}
      >
        {showFilterGroup ? (
          <TableFilters
            module={module}
            total={getParentTotal()}
            values={mainStatusTypes}
            selected={
              filterGroup[getCurrentSectionKey()]
              && filterGroup[getCurrentSectionKey()][savedSearchItemId]
                ? filterGroup[getCurrentSectionKey()][savedSearchItemId]
                : 1
            }
            onFilterSelected={handleFilterGroupSelected}
          />
        ) : ''}
        <Grow/>
        {/*<ExportButton
          module={module}
          fields={reportFields}
          filters={newFilters}
          sort={sort}
          ids={_.map(rowsSelected, (item) => item.id)}
        />        */}
        {/*hasFeaturePush && <OfferInboxButton
          filters={newFilters}
          sort={sort}
          ids={_.map(rowsSelected, (item) => item.id)}
          sms={client ? client.sms_available : false}
        />*/}
      </MTToolbar>
    </>)
  }

  const formattedValue = (value) => {
    if (_.isString(value) && (
      moment(value, 'YYYY-MM-DDTHH:mm:ss.sssZ', true).isValid() ||
      moment(value, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid() ||
      moment(value, 'YYYY-MM-DD', true).isValid()
    )) {
      return moment(value).format('DD/MM/YYYY');
    } else if (!_.isArray(value) && !_.isObject(value)) {
      return value;
    }
  }

  const dataQuery = async () => {
    try {
      const totalCount = totalByStatusGroup ? totalByStatusGroup : (total ? total.total : 0);
      if ((!renderData || !renderData.data || renderData.data.length <= 0) || (!columns || columns.length <= 0)) {
        return {data: [], page: 0, totalCount: 0}
      }
      const formattedData = renderData.data.map(row => {
        return {
          ...row,
          interviewr_date: formattedValue(row.interviewr_date),
        };
      });
      return {data: formattedData, page: page[getCurrentSectionKey()], totalCount}
    } catch (e) {
      return {data: [], page: 0, totalCount: 0}
    }
  }

  const columns = getColumns(inboxFields, statusTypes)

  return (
    <FavoritesTableStyled>
      {module && getCurrentSectionKey() && <InboxBySectionComponent/>}
      <MaterialTable
        tableRef={tableRef}
        columns={columns}
        isLoading={isFetching}
        data={dataQuery}
        options={tableOptions}
        onSelectionChange={checkRow}
        components={tableComponents}
        localization={getCurrentSectionKey() !== SECTION_KEYS.SAVED_SEARCHES_ITEM ? i18n.tableLocalization : i18n.savedSearchesItemTableLocalization}
        onRowClick={handleRowClick}
        draggable={false}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onOrderChange={(orderedColumnId, orderDirection) => handleOrderChange(columns[orderedColumnId], orderDirection)}
      />
      {module !== MODULES.ID_EXIT_INTERVIEW && <ModalChangeStatus
        open={openChangeStatusModal}
        handleClose={handleCloseChangeStatusModal}
        rowsSelected={rowsSelected}
        onSubmit={onSubmitChangeStatus}
        options={statusTypesByUser}
      />}
      {module !== MODULES.ID_EXIT_INTERVIEW && tagsTypes && tagsTypes.length > 0 &&
        <ModalChangeTagsQuery
          open={openChangeTagsModal}
          handleClose={() => setOpenChangeTagsModal(false)}
          rowsSelected={rowsSelected}
          tagsTypes={tagsTypes}
          currentTags={currentTags}
          onSubmit={onSubmitChangeTags}
        />
      }
      {module !== MODULES.ID_EXIT_INTERVIEW && <ModalMailCustomQuery
        open={isOpenMailCustomModal}
        handleClose={() => setOpenMailCustomModal(false)}
        onSubmit={handleSubmitMailCustom} {...props}
      />}
      {module !== MODULES.ID_EXIT_INTERVIEW &&
          <ModalAnnouncementQuery
        open={announcementModal}
        handleClose={() => setAnnouncementModal(false)}
        onSubmit={onSubmitAnnouncement}
        sms={client ? client.sms_available : false}
        self={auth ? auth : null}
        rowsSelected={totalRowsSelected}
        data={rowsSelected}
        suggestChangeStatusModal={suggestChangeStatusModal}
      />}
      {module !== MODULES.ID_EXIT_INTERVIEW && FeatureGuard.canAccessToFunnelStatus() && <ModalSuggestChangeStatusAnnouncement
        open={suggestChangeStatusModal ? suggestChangeStatusModal.isOpenModalSuggestChangeStatus : false}
        handleClose={() => suggestChangeStatusModal.setIsOpenModalSuggestChangeStatus(false)}
        i18n={i18n}
        statusFunnelTypes={statusFunnelTypes}
        module={module}
        data={rowsSelected}
      />}
      {module !== MODULES.ID_EXIT_INTERVIEW && <ModalRequireDocQuery
        open={requireDocModal}
        handleClose={() => setRequireDocModal(false)}
        onSubmit={onSubmitRequireDoc}
      />}
    </FavoritesTableStyled>
  )
}

CustomTable.defaultProps = {
  title: 'Inbox',
  showChangeStatus: false,
  actions: [],
  resetFiltersOnLoad: true,
  notificationMultipleEnabled: false,
}

CustomTable.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  showFilterGroup: PropTypes.bool,
  title: PropTypes.string,
  showChangeStatus: PropTypes.bool,
  actions: PropTypes.array,
  offer: PropTypes.object,
  client: PropTypes.object,
  notificationMultipleEnabled: PropTypes.bool
}

export default CustomTable
