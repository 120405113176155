import React, { useEffect, useState } from 'react'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import Grid from '@material-ui/core/Grid'
import { ModalStyled } from './styled'
import Modal from '@material-ui/core/Modal'
import { ModalHeaderProcess, RegularButton } from '../../atoms'
import { patchUser } from '../../../api/index';
import { useMutation } from "@tanstack/react-query";

const customStyles = {
  overflow: 'scroll'
}

const ModalDisableUser = ({ open, handleClose, i18n, userData, currentStatus, onDataUpdate, selectedRows }) => {
  const [toggleCurrentStatus, setToggleCurrentStatus] = useState(null);
  const [userDataState, setUserDataState] = useState(null);
  const [selectedRowsState, setSelectedRowsState] = useState([]);

  useEffect(() => {
    if (open) {
      const newCurrentStatus = userData ? userData.is_active : false;

      setToggleCurrentStatus(newCurrentStatus);
      setUserDataState(userData);
      setSelectedRowsState(selectedRows);
    }
  }, [open, userData, selectedRows]);


  const changeStatusUser = async (data) => {
    try {
      const response = await patchUser(data.id, data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  const mutationChangeStatusUser = useMutation(changeStatusUser);

  const areAllUsersActive = selectedRows.every(row => row.is_active);
  const areAllUsersInactive = selectedRows.every(row => !row.is_active);

  const handleClickDisable = async (e) => {
    e.preventDefault();

    try {
      if (userDataState && userDataState.id) {
        await mutationChangeStatusUser.mutateAsync(
          {
            id: userDataState.id,
            is_active: !toggleCurrentStatus
          },
          {
            onSuccess: () => {
              handleClose();
              onDataUpdate();
            },
          }
        );
      } else if (selectedRows.length > 0) {
        const promises = selectedRows.map((row) => {
          const newUserStatus = toggleCurrentStatus ? !areAllUsersInactive : !areAllUsersActive;
          return mutationChangeStatusUser.mutateAsync(
            {
              id: row.id,
              is_active: newUserStatus
            },
            {
              onSuccess: () => {
                handleClose();
                onDataUpdate();
              },
              onError: (error) => {
                console.error(`Error en la mutación para el usuario ${row.id}:`, error);
              },
            }
          );
        });

        await Promise.all(promises);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalClose = () => {
    setToggleCurrentStatus(null);
    setUserDataState(null);
    setSelectedRowsState([]);
    handleClose();
  };

  const getTitleForConfirmation = () => {
    if (selectedRowsState.length > 0) {
      return areAllUsersActive
        ? i18n.user_management.confirmDisableUsers
        : i18n.user_management.confirmEnableUsers;
    } else if (userDataState && toggleCurrentStatus) {
      return i18n.user_management.confirmDeactivation
        .replace('{name}', `${userDataState.name || ''}`)
        .replace('{surname}', `${userDataState.surname || ''}`);
    } else if (userDataState) {
      return i18n.user_management.confirmActivation
        .replace('{name}', `${userDataState.name || ''}`)
        .replace('{surname}', `${userDataState.surname || ''}`);
    }
    return '';
  };

  const getTitleForButton = () => {
    if (userDataState && userDataState.id) {
      return !toggleCurrentStatus
        ? i18n.user_management.buttonConfirmEnable
        : i18n.user_management.buttonConfirmDisable;
    } else if (selectedRowsState.length > 0) {
      return areAllUsersActive
        ? i18n.user_management.buttonConfirmDisableUsers
        : i18n.user_management.buttonConfirmEnableUsers;
    }
    return '';
  };

  return (
    <>
      <Modal
        style={customStyles}
        className="modal-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleModalClose}
      >
        <ModalStyled>
          <ModalHeaderProcess closeClick={handleModalClose} hasCloseIcon={true} />
          <div className="modal-body">
            <form>
              <Grid container alignItems="center">
                {(selectedRowsState.length > 0 || (userDataState && userDataState.id)) && (
                  <Grid item xs={12} sm={12}>
                    <h4>{getTitleForConfirmation()}</h4>
                    {selectedRowsState.length > 0 &&
                      selectedRowsState.map((row) => (
                        <h5 key={row.id}>{row.name} {row.surname}</h5>
                      ))}
                  </Grid>
                )}
              </Grid>
              <div className="button-wrapper">
                <RegularButton
                  className="button-custom"
                  title={getTitleForButton()}
                  onClick={handleClickDisable}
                /></div>
            </form>
          </div>
        </ModalStyled>
      </Modal>
    </>
  )
}

const enharce = compose(withI18n)

export default enharce(ModalDisableUser)
